import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageYamlPhotographierArcheVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "photographierArcheVoieLactee" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["traitementImagesVoieLactee", "hessII360", "megalaxyZoom"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteePtGUI"
            "voieLacteeArcheApresRedressement"
            "voieLacteeRedressementdeLarcheDansPhotoshop"
            "voieLacteeArchedeLaVoieLacteeSurUnePlageDuMorbihan"
            "voieLacteeSortiedassemblageDuLogicielPanoramique"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeArcheImageDuSol"
            "voieLacteeArcheImageAvecDuSolEtDuCiel"
            "voieLacteeArcheImageDuZenith"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function PhotographieArcheVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(
    resultatsRequete,
    "pageYamlPhotographierArcheVoieLactee"
  )
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortraitMoyen"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Principes">
        <p>
          Les photographies de l’arche de la Voie lactée sont des{" "}
          <strong>panoramiques</strong> du ciel étoilé. La surface qu’ils
          couvrent est vaste : du sud jusqu’au nord, du sol jusqu’au zénith. Ils
          sont donc composés <strong>nombreuses photos</strong> (parfois
          plusieurs dizaines). Après assemblage, ils sont travaillés sous un
          logiciel de retouche d’images pour arriver au résultat souhaité.
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeArchedeLaVoieLacteeSurUnePlageDuMorbihan"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="Le matériel">
        <p>
          Vous devez utiliser{" "}
          <strong>un objectif grand angle et une rotule panoramique</strong>,
          indispensables pour réaliser des panoramas composés de nombreuses
          photos. J’utilise une rotule Bushman Gobi qui est très légère et très
          compacte.
        </p>
      </Section>
      <Section titre="Quand ?">
        <p>
          La Voie lactée doit être <strong>aux environs du zénith</strong> pour
          qu’elle soit bien visible sur les clichés. C’est-à-dire à partir de
          mai en fin de nuit, jusqu’à fin septembre en début de nuit.
        </p>
        <p>
          Notez qu’une Voie lactée pas trop haute (entre 60° et 80°){" "}
          <strong>facilitera l’assemblage du panoramique</strong> (moins de
          déformations de l’arche).
        </p>
      </Section>
      <Section titre="Où ?">
        <p>
          Vous devez repérer de préférence un endroit{" "}
          <strong>vierge de pollution lumineuse du sud jusqu’au nord</strong>.
          De tels endroits sont devenus trop rares en France. Il y a quelques
          plages de Bretagne sud, des endroits reculés de l’Ardèche, de la
          Drôme, etc. Et bien sûr, tous les massifs montagneux, à condition de
          monter sur les hauteurs. Aidez-vous des cartes de pollution lumineuse.
        </p>
      </Section>
      <Section titre="Prise de vue">
        <p>
          Pour réaliser le panorama, vous devez prendre{" "}
          <strong>plusieurs « rangées »</strong> de photos (cela dépend de la
          longueur focale que vous utilisez), du sol jusqu’au zénith. Commencez
          par une prise de vue du sol, puis du sol et du ciel, pour finir par le
          zénith (quelques photos suffiront alors). Ne changez{" "}
          <strong>
            ni la longueur focale ni les réglages de votre appareil{" "}
          </strong>
          (vérifiez que vous êtes bien en mode « manuel »).
        </p>
        <p>Avant de démarrer la prise de vue :</p>
        <ul>
          <li>Vérifiez que votre trépied est (à peu près) à niveau.</li>
          <li>
            S’il y a des zones plus claires dans le panorama (pollution
            lumineuse), vérifiez que votre réglage ne les surexpose pas.
          </li>
          <li>
            NB : La technique de prise de vue de chaque photo composant le
            panorama est décrite dans l’article «{" "}
            <Lien codePageCible="photographierLaVoieLactee">
              Photographier la Voie lactée
            </Lien>{" "}
            ».
          </li>
        </ul>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "voieLacteeArcheImageDuSol")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "voieLacteeArcheImageAvecDuSolEtDuCiel"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "voieLacteeArcheImageDuZenith")}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="Prétraitement dans Lightroom">
        <p>
          Prétraitez toutes vos photos dans <strong>Lightroom</strong> en
          utilisant les mêmes paramètres : sur une de vos photos, corrigez
          l’exposition et ses défauts optiques (
          <strong>distorsions et surtout vignetage</strong>). Puis synchronisez
          ces paramètres avec les autres photos de votre série (dans la
          bibliothèque : « Synch param » en bas à droite).
        </p>
        <p>
          <strong>ATTENTION</strong> : ne réduisez surtout pas le bruit à cette
          étape pour ne pas perdre de détails. Vous le ferez en toute fin de
          processus, une fois les retouches esthétiques réalisées.
        </p>
        <p>Exportez finalement vos images au format TIFF.</p>
      </Section>
      <Section titre="Assemblage du panorama dans PTGui">
        <p>
          Fusionnez ensuite votre panorama dans <strong>PTGui</strong> (ou autre
          logiciel équivalent comme AutoPano ou Microsoft ICE). S’il n’est pas
          correctement fusionné, <strong>vous devez aider le logiciel</strong> à
          faire l’assemblage. Ajoutez des points de contrôle manuellement, ou
          automatiquement (« Control points &gt; Generate control points »).
        </p>
        <p>
          Une fois le panorama assemblé, les étoiles du zénith seront peut-être
          écrasées par la projection choisie par défaut par PTGUI.{" "}
          <strong>Il faut la changer</strong>. Dans l’éditeur de panorama,
          essayez par exemple la projection « Mercator ».
        </p>
        <Image
          image={obtenirImage(images, "voieLacteePtGUI")}
          langue={page.langue}
        />
        <p>
          Sauvegardez enfin votre panorama. Si des éléments de votre prise de
          vue n’apparaissent pas comme vous le souhaitez (ex : météorite), il
          est possible de sauvegarder le panorama en fichier PSD pour Photoshop
          (avec 1 calque pour chaque image). Vous pourrez faire apparaître les
          éléments souhaités à l’aide de masques de fusion.
        </p>
      </Section>
      <Section titre="Finitions dans Photoshop">
        <p>
          Nous allons utiliser Photoshop pour redresser notre image. Ouvrez
          l’image, puis recadrez-la de façon à ne pas avoir de bords trop
          déformés.
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeSortiedassemblageDuLogicielPanoramique"
          )}
          langue={page.langue}
        />
        <p>
          Il est parfois nécessaire de <strong>redresser</strong> les
          panoramiques assemblés, et c’est souvent l’étape la plus délicate.
          Rendez-vous dans le menu « Édition &gt; Déformation de la marionnette
          » (versions CS5 et supérieures). Dupliquez le calque d’arrière-plan
          (Ctrl + j) sur un nouveau calque, et positionnez 4 points aux 4 coins
          du calque. Puis tirez les 2 points du bas comme indiqué sur l’image
          ci-dessous. Terminez le redressement en posant d’autres points.
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeRedressementdeLarcheDansPhotoshop"
          )}
          langue={page.langue}
        />
        <p>
          Recadrez, et vous obtenez une image prête pour les retouches
          esthétiques finales !
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeArcheApresRedressement")}
          langue={page.langue}
        />
      </Section>
    </LayoutTutorielFR>
  )
}
